module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-163150682-16","AW-645653569"],"gtagConfig":{"optimize_id":"OPT-WQR2CH7","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1522365844617885"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":false,"firestore":false,"storage":false,"messaging":false,"functions":false,"performance":false}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/opt/render/project/src/src/components/BlogLayout/index.jsx","pages":"/opt/render/project/src/src/components/MdxPageLayout/index.jsx","default":"/opt/render/project/src/src/components/Layout/index.jsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dc7fa0b6bf2b4d3fb48716b8cf46b1d2@o421703.ingest.sentry.io/5341868","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
